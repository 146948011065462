import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { selectLastRenewDate } from '../../../../redux/selectors';
import { getNextRenewalDateString } from '../../../../helpers/utils';

export const PricingCardWrapperClasses =
  'h-full flex flex-col justify-start gap-5 rounded-2xl px-7 py-6 max-w-72 bg-white border border-slate-300';

/**
 *
 * @param {number} price Price
 * @returns {string} Price style classes
 */
export function PriceStyleClasses(price: string): string {
  const priceString = price.length;
  if (priceString >= 6) {
    return 'text-2xl';
  } else {
    return 'text-4xl';
  }
}

/**
 *
 * @param {number} price Price
 * @returns {string} Currency Symbol style classes
 */
export function CurrencySymbolClasses(price: string): string {
  const priceString = price.length;
  if (priceString >= 6) {
    return 'text-xl';
  } else {
    return 'text-3xl';
  }
}

/**
 *
 * @returns {React.ReactNode} Current plan component
 */
export const CurrentPlanComponent: React.FC = () => {
  const lastRenewalDate = useSelector(selectLastRenewDate);
  return (
    <div className="flex min-h-[68px] flex-col items-center justify-center rounded-lg bg-indigo-50 text-indigo-600">
      <p>
        <FormattedMessage defaultMessage="Your current plan." id="PgXU8a" />
      </p>
      <p className="text-sm">
        <FormattedMessage
          defaultMessage="Renews on {date}"
          values={{
            date: getNextRenewalDateString(lastRenewalDate),
          }}
          id="NWED1U"
        />
      </p>
    </div>
  );
};

/**
 *
 * @param {boolean} proTier Pro Tier Boolean
 * @returns {React.ReactNode} Usage component
 */
export const CurrentUsageComponent: React.FC<{ proTier?: boolean }> = ({
  proTier = false,
}) => {
  const meetingsUsed = useSelector(
    (state: RootState) => state.user.plan.free.used
  );
  const meetingsAllowed = useSelector(
    (state: RootState) => state.user.plan.free.allowance
  );
  const creditsUsed = useSelector(
    (state: RootState) => state.user.plan.free.aiCredits.used
  );
  const creditsAllowed = useSelector(
    (state: RootState) => state.user.plan.free.aiCredits.allowance
  );

  const meetingsAvailable = (
    <div className="flex w-full flex-col gap-1">
      <p className="text-xs text-slate-700">
        <FormattedMessage
          defaultMessage="{used} of your {total} meetings used."
          id="lljHHy"
          values={{
            used: meetingsUsed,
            total: meetingsAllowed,
          }}
        />
      </p>
      <div className=" w-full overflow-hidden rounded-full bg-gray-200">
        <div
          className="h-1 rounded bg-indigo-600"
          style={{
            width: `${(meetingsUsed / meetingsAllowed) * 100}%`,
          }}
        />
      </div>
    </div>
  );

  const creditsAvailable = (
    <div className="flex w-full flex-col gap-1">
      <p className="text-xs text-slate-700">
        <FormattedMessage
          defaultMessage="{used} of your {total} AI credits used."
          id="4tBZfC"
          values={{
            used: creditsUsed,
            total: creditsAllowed,
          }}
        />
      </p>
      <div className=" w-full overflow-hidden rounded-full bg-gray-200">
        <div
          className="h-1 rounded bg-indigo-600"
          style={{
            width: `${(creditsUsed / creditsAllowed) * 100}%`,
          }}
        />
      </div>
    </div>
  );
  return (
    <div className="flex min-h-[68px] flex-col items-center justify-start gap-3 rounded-lg py-1 text-indigo-600">
      {!proTier ? meetingsAvailable : null}
      {creditsAvailable}
    </div>
  );
};
